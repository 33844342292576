body {
  margin: 0;
  font-family: "American Typewriter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #42033D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.title {
  color: #ffffff;
  text-decoration: underline;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.input-container {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  width: 351px;
  height: 50px;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 10px 0 0 10px;
}

.submit-button {
  background-color: #679289;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.quoteBox {
  height: 348px;
  width: 345px;
  background-color: #680e4b;
  border: 3px solid;
  border-color: #7c72a0;
  border-radius: 10px;
  height: 348px;
  width: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quoteBox .quoteText {
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 317px;
}

.quoteNumbersContainer {
  height: 50px;
  position: relative;
  width: 351px;
  display: flex;
  justify-content: space-between;
}

.quoteNumberBox {
  background-color: #854798;
  border-radius: 10px;
  height: 50px;
  left: 0;
  top: 0;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.quoteNumberBox.active {
  background-color: #7c238c;
}

.quoteNumberBox.visible {
  visibility: visible;
}

.quoteNumberBox .quoteNumberText {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.prev-guesses-container {
  color: #ffffff;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.congrats-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.close-button {
  background-color: #679289;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.modal-content {
  text-align: center;
}

textarea {
  width: 100%;
  margin-bottom: 10px;
}

button {
  background-color: #679289;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-datalist {
  position: absolute;
  margin-top: 5px; /* Adjust as needed */
  padding: 5px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1;
  max-height: 150px; /* Adjust as needed */
  overflow-y: auto;
}

.custom-datalist option {
  padding: 5px;
}

.correct-answer {
  color: #ffffff;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.title,
.quoteText,
.quoteNumberText,
.prev-guesses-container,
.congrats-modal,
.quoteNumberBox {
  font-family: "American Typewriter", sans-serif;
}